<template>
  <BCard>
    <b-row class="justify-content-between mt-1">
      <b-col class="col-md-2">
        <BFormGroup
          label="Поиск"
        >
          <BFormInput
            v-model="filter.search"
            type="search"
            @input="searchCon"
          />
        </BFormGroup>
      </b-col>
      <b-col class="col-md-3 text-right">
        <BButton
          style="margin-top: 27px"
          variant="primary"
          @click="$router.push(`/users/add`)"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </BButton>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <BTable
        id="user-table"
        class="bg-light-secondary px-1 rounded mt-1"
        :tbody-tr-class="['tableRowClass']"
        :items="userList.results"
        :fields="fields"
        show-empty
        responsive
        hover
        small
      >
        <template #empty>
          <empty />
        </template>
        <template #cell(first_name)="props">
          <div class="d-flex align-items-center">
            <b-avatar-group class="mr-50 d-none">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                :title="props.item.first_name"
                :src="props.item.profile_picture"
              />
            </b-avatar-group>
            {{ props.item.first_name }}
          </div>
        </template>
        <template #cell(roles)="props">
          <div>
            <b-badge
              v-for="(item, index) in props.item.roles"
              :key="index"
              variant="success"
              class="badge__style"
            >
              {{ item.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(role)="props">
          <BBadge
            v-for="role in props.item.role"
            :key="role.id"
          >
            {{ role.name }}
          </BBadge>
        </template>
        <template #cell(is_active)="props">
          <b-form-checkbox
            v-model="props.item.is_active"
            disabled
            checked="true"
            name="check-button"
            switch
            inline
          >
            <span class="switch-icon-left">
              <feather-icon
                icon="CheckIcon"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="XIcon"
              />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(actions)="props">
          <div class="d-flex align-items-center justify-content-center">
            <BButton
              size="sm"
              class="mr-1"
              variant="warning"
              @click="$router.push(`/users/edit/${props.item.id}`)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </BButton>
            <BButton
              size="sm"
              variant="danger"
              @click="deleteData(props.item)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </BButton>
          </div>
        </template>
      </BTable>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="filter.page_size"
            :options="[5, 10, 15]"
            class="mx-1"
            @change="fetchActionList()"
          />
          <span class="ml-1 text-nowrap"> строк( {{ userList.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="filter.page"
            :total-rows="userList.count"
            :per-page="filter.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-overlay>
  </BCard>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BCard,
    BFormCheckbox,
    BButton,
    BBadge,
    BTable,
    BFormGroup,
    BFormInput,
    BAvatarGroup,
    BAvatar,
    BOverlay,
    BRow,
    BCol,
    empty,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      filter: {
        search: null,
        page: 1,
        page_size: 10,
      },
      fields: [
        {
          key: 'id',
          label: '№',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'first_name',
          label: 'Имя',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'last_name',
          label: 'Фамилия',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'username',
          label: 'Логин',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'roles',
          label: 'Роли',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'is_active',
          label: 'Статус',
          sortable: true,
          thClass: 'tableHeadClass',
        },
        {
          key: 'actions',
          label: 'Действия',
          sortable: false,
          thClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('users', ['userList']),
  },
  mounted() {
    this.fetchActionList()
  },
  methods: {
    ...mapActions('users', ['FETCH_ALL_USERS', 'DELETE_USER']),

    async fetchActionList() {
      try {
        this.loading = true
        await this.FETCH_ALL_USERS(this.filter)
      } catch (error) {
        await this.$_errorToast()
        this.loading = false
      }
      this.loading = false
    },

    searchCon() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.FETCH_ALL_USERS(this.filter)
      }, 200)
    },

    deleteData(item) {
      this.$bvModal
        .msgBoxConfirm('Вы действительно хотите удалить эту запись?', {
          title: 'Удалить',
          okVariant: 'danger',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отменить',
          hideHeaderClose: false,
          centered: true,
        })
        .then(success => {
          if (success) {
            this.DELETE_USER({ id: item.id })
              .then(() => {
                this.filter.page = 1
                this.fetchActionList()
                this.$_okToast()
              })
              .catch(this.$_errorToast)
          }
        })
    },

    changePage(page) {
      this.filter.page = page

      this.fetchActionList()
    },
  },
}
</script>

<style lang="scss">
.badge__style {
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
